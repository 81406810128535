import "./App.css";
import { useState, useEffect } from "react";
import {Grid} from "@mui/material";
import logoSC from './images/sondeociudadano/logo.png' // relative path to image
import logoQP from './images/qp/logo.png' // relative path to image
import logoDV from './images/datavoices/logo.png' // relative path to image
import favIconSC from './images/sondeociudadano/favicon.ico' // relative path to image
import favIconQP from './images/qp/favicon.ico' // relative path to image
import favIconDV from './images/datavoices/favicon.ico' // relative path to image
import thanksDV from './images/datavoices/GraciasIcon.png' // relative path to image
import thanksQP from './images/qp/GraciasQP.png' // relative path to image
import thanksSC from './images/sondeociudadano/GraciasSondeo.png' // relative path to image
import React from 'react';
import LoadingSpinner from "./LoadingSpinner";

function App() {
    const [data, setData] = useState('defaultValue');
    const [hasResponseData, setHasResponseData] = useState('defaultValue');
    const [loaded, setLoaded] = useState(false);
    const [currentIndex, setCurrentIndex] =  useState(0);
    const [pollEnd, setPollEnd] =  useState(false);
    const [arr, setArr] = React.useState([]);
    const currentURL = "https://app.sondeopolls.com" //window.location.protocol + "//" + window.location.host;
    const [skinType, setSkinType] = useState(0);

    const handleClick = (index,question,answer) => {
        const obj = {};
        obj.id = Math.random();
        obj.question = question
        obj.answer = answer

        arr.push(
            obj
        );

        setArr(arr);

        setCurrentIndex( index + 1);
        if (currentIndex >= data.questions.length - 1) {
            setPollEnd(true);
            sendData()
        }
    };

    function hideQuestion(index) {
        document.getElementById(index).style.display = 'none';
    }

    function sendData() {
        const params = new URLSearchParams(window.location.pathname.replace("/",""));
        var isTest = false;

        if (params.has("test")) {
            if (params.get("test").replace("/","") === "1") {
                isTest = true;
            }
        }

        if (isTest === false) {
            var questionsArray = [];
            var answersArray = [];

            var jsonObject = new Object;


            jsonObject.name = "";

            jsonObject.email = "";
            jsonObject.gender = "";
            jsonObject.age_range = "";
            var facebookPayload = JSON.stringify(jsonObject);

            var facebookId = "";

            arr.forEach((element) => {
                questionsArray.push(element.question);
                answersArray.push(element.answer);
            });

            var jsonObject = new Object;
            jsonObject.questions = questionsArray;
            jsonObject.answers = answersArray;
            jsonObject.pollId = data.pollId;
            jsonObject.facebookPayload = facebookPayload;
            jsonObject.facebookId = facebookId;
            var request = JSON.stringify(jsonObject);

            fetch(`${currentURL}/result`, {
                method: 'POST',
                body: request,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((response) => response.json())
        }
    }

    function checkResponse() {
        const host = window.location.host;
        var icon;
        if (host === 'responde.sondeociudadano.mx') {
            setSkinType(1);
            document.title = "Sondeo Ciudadano";
            icon = favIconSC;
        } else if (host === 'app.quickipoll.com') {
            setSkinType(2);
            document.title = "Quickipoll";
            icon = favIconQP;
        } else if (host === 'datavoices.d1z9bzfg1lxhyh.amplifyapp.com') {
            setSkinType(3);
            document.title = "Datavoices";
            icon = favIconDV;
        } else if ('localhost:3000') {
            setSkinType(1);
            document.title = "Dev";
            icon = favIconSC;
        }

        try {

            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = icon;

            const params = new URLSearchParams(window.location.pathname.replace("/", ""));
            var jsonObject = new Object;


            jsonObject.pollId = params.get("cookie").replace("/", "");

            var isTest = false;


            if (params.has("test")) {
                if (params.get("test").replace("/", "") === "1") {
                    isTest = true;
                }
            }

            jsonObject.facebookId = "";
            var hasResponseRequest = JSON.stringify(jsonObject);


            fetch(`${currentURL}/hasresponse`, {
                method: 'POST',
                body: hasResponseRequest,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((response) => response.json())
                .then((hasResponseData) => {
                    if (hasResponseData.succes === false && isTest === false) {
                        setPollEnd(true);
                        setData("piktia");
                    } else {
                        fetchPollData();
                    }
                })
                .finally(() => {
                });
        } catch (error) {
            setPollEnd(true);
            setData("piktia");
        }
    }

    function fetchPollData() {
        const params = new URLSearchParams(window.location.pathname.replace("/",""));

        fetch(
            `${currentURL}/encuestaDetail/${params.get("cookie")}`,
            {
                headers :  new Headers({
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                    'Access-Control-Allow-Credentials': true,
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => setData(data))
            .finally(() => setLoaded(true));
    }


    useEffect(() => {
        checkResponse();
    }, []);
    return (
        <div className="App">
            {skinType === 1 &&
                <Grid container className="header-sondeo" columns={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid item xs={4}>
                        <img
                            className="header-img-sondeo"
                            src={logoSC}
                            alt=""
                        />
                    </Grid>
                </Grid>
            }

            {skinType === 2 &&
                <Grid container className="header-qp" columns={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid item xs={2}>
                        <img
                            className="header-img-qp"
                            src={logoQP}
                            alt=""
                        />
                    </Grid>
                    <Grid item xs={10} class="header-text-qp">
                        {data !== 'defaultValue' && data.skin === skinType && data.name}
                    </Grid>
                </Grid>
            }

            {skinType === 3 &&
                <Grid container className="header" columns={{ xs: 12, sm: 12, md: 12 }}>
                    <Grid item xs={4}>
                        <img
                            className="header-img"
                            src={logoDV}
                            alt=""
                        />
                    </Grid>
                    <Grid item xs={8} class="header-text">
                        {data !== 'defaultValue' && data.skin === skinType && data.name}
                    </Grid>
                </Grid>
            }

            {data === 'defaultValue' ? <LoadingSpinner /> :
                <div className="div-question-container">
                    {skinType === 1 && data !== 'defaultValue' && data !== 'piktia' && data.succes && data.skin === skinType   && data.questions.map((question, index) => {
                return (
                    <div key={index} id={index}
                         hidden={index !== currentIndex}

                         className="div-question-sondeo"
                    >
                        <div class="question-text-sondeo">
                            <h2>{question.question}</h2>
                        </div>
                        {question.type === 3  ?
                            <div>
                                {   question.answers.length === 1  ?
                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 2 }} minHeight="360px">
                                        {question.answers.map((answer, indexAnswer) => {
                                            return (
                                                <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                    <item>
                                                        <div class="photo-card">
                                                            <img
                                                                class="photo-card-img"
                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                alt=""
                                                            />
                                                            <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                        </div>
                                                    </item>
                                                </Grid>
                                            );
                                        })}
                                    </Grid> :
                                    question.answers.length === 2  ?
                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 4 }} minHeight="360px">
                                        {question.answers.map((answer, indexAnswer) => {
                                            return (
                                                <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                    <item>
                                                        <div class="photo-card">
                                                            <img
                                                                class="photo-card-img"
                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                alt=""
                                                            />
                                                            <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                        </div>
                                                    </item>
                                                </Grid>
                                            );
                                        })}
                                    </Grid> :
                                    question.answers.length === 3  ?
                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 9 }} minHeight="360px">
                                        {question.answers.map((answer, indexAnswer) => {
                                            return (
                                                <Grid item xs={2} sm={2} md={3} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                    <item>
                                                        <div class="photo-card">
                                                            <img
                                                                class="photo-card-img"
                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                alt=""
                                                            />
                                                            <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                        </div>
                                                    </item>
                                                </Grid>
                                            );
                                        })}
                                    </Grid> :
                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 8 }} minHeight="360px">
                                        {question.answers.map((answer, indexAnswer) => {
                                            return (
                                                <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                    <item>
                                                        <div class="photo-card">
                                                            <img
                                                                class="photo-card-img"
                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                alt=""
                                                            />
                                                            <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                        </div>
                                                    </item>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                }
                                <div className="footer">
                                    <div className="footer-text-sondeo">
                                        SELECCIONA UNA OPCIÓN
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <Grid container rowSpacing={0} columnSpacing={0} columns={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }} >
                                    <Grid item xs={1} sm={1} md={4} lg={5} xl={7}>
                                        <item>
                                        </item>
                                    </Grid>
                                    <Grid item xs={18} sm={18} md={12} lg={10} xl={6}>
                                        <item>
                                            <div className="photo-card-big">
                                                <img
                                                    className="photo-card-img-big"
                                                    src={`https://app.sondeopolls.com/assets/files/${question.answers[0].description}`}
                                                    alt=""
                                                />
                                            </div>
                                        </item>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={4} lg={5} xl={7}>
                                        <item>
                                        </item>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={0} columnSpacing={0} columns={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }}>
                                    <Grid item xs={2} sm={2} md={5} lg={6} xl={7}>
                                        <item>
                                        </item>
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={4} lg={3} xl={2} onClick={() => {handleClick(index,question.questionId,1)}}>
                                        <item>
                                            <div className="yes-no">
                                                <div className="yes-no-text-qp">
                                                    Sí
                                                </div>
                                            </div>
                                        </item>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                        <item>
                                        </item>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4} lg={3} xl={2} onClick={() => {handleClick(index,question.questionId,2)}}>
                                        <item>
                                            <div className="yes-no">
                                                <div className="yes-no-text-qp">
                                                    No
                                                </div>
                                            </div>
                                        </item>
                                    </Grid>

                                    <Grid item xs={2} sm={2} md={5} lg={6} xl={7}>
                                        <item>
                                        </item>
                                    </Grid>
                                </Grid>
                                <div className="yes-no-footer">
                                    <div className="yes-no-footer-text">
                                        SELECCIONA UNA OPCIÓN
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                );

            })}

                    {skinType === 2 && data !== 'defaultValue' && data !== 'piktia' && data.succes && data.skin === skinType   && data.questions.map((question, index) => {
                        return (
                            <div key={index} id={index}
                                 hidden={index !== currentIndex}

                                 className="div-question-qp"
                            >
                                <div class="question-text-qp">
                                    <h2>{question.question}</h2>
                                </div>
                                {question.type === 3  ?
                                    <div>
                                        {question.answers.length === 1 ?
                                            <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 2 }} minHeight="360px">
                                                {question.answers.map((answer, indexAnswer) => {
                                                    return (
                                                        <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                            <item>
                                                                <div class="photo-card">
                                                                    <img
                                                                        class="photo-card-img"
                                                                        src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                        alt=""
                                                                    />
                                                                    <div class="photo-card-text-qp">{answer.answer}</div>
                                                                </div>
                                                            </item>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid> :
                                            question.answers.length === 2 ?
                                                <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 4 }} minHeight="360px">
                                                    {question.answers.map((answer, indexAnswer) => {
                                                        return (
                                                            <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                                <item>
                                                                    <div class="photo-card">
                                                                        <img
                                                                            class="photo-card-img"
                                                                            src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                            alt=""
                                                                        />
                                                                        <div class="photo-card-text-qp">{answer.answer}</div>
                                                                    </div>
                                                                </item>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid> :
                                                question.answers.length === 2 ?
                                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 9 }} minHeight="360px">
                                                        {question.answers.map((answer, indexAnswer) => {
                                                            return (
                                                                <Grid item xs={2} sm={2} md={3} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                                    <item>
                                                                        <div class="photo-card">
                                                                            <img
                                                                                class="photo-card-img"
                                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                                alt=""
                                                                            />
                                                                            <div class="photo-card-text-qp">{answer.answer}</div>
                                                                        </div>
                                                                    </item>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid> :
                                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 8 }} minHeight="360px">
                                                        {question.answers.map((answer, indexAnswer) => {
                                                            return (
                                                                <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                                    <item>
                                                                        <div class="photo-card">
                                                                            <img
                                                                                class="photo-card-img"
                                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                                alt=""
                                                                            />
                                                                            <div class="photo-card-text-qp">{answer.answer}</div>
                                                                        </div>
                                                                    </item>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>

                                        }
                                        <div className="footer">
                                            <div className="footer-text-qp">
                                                SELECCIONA UNA OPCIÓN
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <Grid container rowSpacing={0} columnSpacing={0} columns={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }} >
                                            <Grid item xs={1} sm={1} md={4} lg={5} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>
                                            <Grid item xs={18} sm={18} md={12} lg={10} xl={6}>
                                                <item>
                                                    <div className="photo-card-big">
                                                        <img
                                                            className="photo-card-img-big"
                                                            src={`https://app.sondeopolls.com/assets/files/${question.answers[0].description}`}
                                                            alt=""
                                                        />
                                                    </div>
                                                </item>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={4} lg={5} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>
                                        </Grid>
                                        <Grid container rowSpacing={0} columnSpacing={0} columns={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }}>
                                            <Grid item xs={2} sm={2} md={5} lg={6} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>

                                            <Grid item xs={6} sm={6} md={4} lg={3} xl={2} onClick={() => {handleClick(index,question.questionId,1)}}>
                                                <item>
                                                    <div className="yes-no">
                                                        <div className="yes-no-text">
                                                            Sí
                                                        </div>
                                                    </div>
                                                </item>
                                            </Grid>

                                            <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                                <item>
                                                </item>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={4} lg={3} xl={2} onClick={() => {handleClick(index,question.questionId,2)}}>
                                                <item>
                                                    <div className="yes-no">
                                                        <div className="yes-no-text">
                                                            No
                                                        </div>
                                                    </div>
                                                </item>
                                            </Grid>

                                            <Grid item xs={2} sm={2} md={5} lg={6} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>
                                        </Grid>
                                        <div className="yes-no-footer">
                                            <div className="yes-no-footer-text">
                                                SELECCIONA UNA OPCIÓN
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        );

                    })}

                    {skinType === 3 && data !== 'defaultValue' && data !== 'piktia' && data.succes && data.skin === skinType   && data.questions.map((question, index) => {
                        return (
                            <div key={index} id={index}
                                 hidden={index !== currentIndex}

                                 className="div-question-dv"
                            >
                                <div class="question-text-dv">
                                    <h2>{question.question}</h2>
                                </div>
                                {question.type === 3  ?
                                    <div>
                                        {question.answers.length === 1 ?
                                            <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 2 }} minHeight="360px">
                                                {question.answers.map((answer, indexAnswer) => {
                                                    return (
                                                        <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                            <item>
                                                                <div class="photo-card">
                                                                    <img
                                                                        class="photo-card-img"
                                                                        src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                        alt=""
                                                                    />
                                                                    <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                                </div>
                                                            </item>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid> :
                                            question.answers.length === 2 ?
                                                <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 4 }} minHeight="360px">
                                                    {question.answers.map((answer, indexAnswer) => {
                                                        return (
                                                            <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                                <item>
                                                                    <div class="photo-card">
                                                                        <img
                                                                            class="photo-card-img"
                                                                            src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                            alt=""
                                                                        />
                                                                        <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                                    </div>
                                                                </item>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid> :
                                                question.answers.length === 3 ?
                                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 9 }} minHeight="360px">
                                                        {question.answers.map((answer, indexAnswer) => {
                                                            return (
                                                                <Grid item xs={2} sm={2} md={3} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                                    <item>
                                                                        <div class="photo-card">
                                                                            <img
                                                                                class="photo-card-img"
                                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                                alt=""
                                                                            />
                                                                            <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                                        </div>
                                                                    </item>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid> :
                                                    <Grid container rowSpacing={3} columnSpacing={3} columns={{ xs: 4, sm: 4, md: 8 }} minHeight="360px">
                                                        {question.answers.map((answer, indexAnswer) => {
                                                            return (
                                                                <Grid item xs={2} sm={2} md={2} key={indexAnswer} onClick={() => {handleClick(index,question.questionId,answer.answerId)}}>
                                                                    <item>
                                                                        <div class="photo-card">
                                                                            <img
                                                                                class="photo-card-img"
                                                                                src={`https://app.sondeopolls.com/assets/files/${answer.description}`}
                                                                                alt=""
                                                                            />
                                                                            <div class="photo-card-text-sondeo">{answer.answer}</div>
                                                                        </div>
                                                                    </item>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                        }
                                        <div className="footer">
                                            <div className="footer-text-sondeo">
                                                SELECCIONA UNA OPCIÓN
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <Grid container rowSpacing={0} columnSpacing={0} columns={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }} >
                                            <Grid item xs={1} sm={1} md={4} lg={5} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>
                                            <Grid item xs={18} sm={18} md={12} lg={10} xl={6}>
                                                <item>
                                                    <div className="photo-card-big">
                                                        <img
                                                            className="photo-card-img-big"
                                                            src={`https://app.sondeopolls.com/assets/files/${question.answers[0].description}`}
                                                            alt=""
                                                        />
                                                    </div>
                                                </item>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={4} lg={5} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>
                                        </Grid>
                                        <Grid container rowSpacing={0} columnSpacing={0} columns={{ xs: 20, sm: 20, md: 20, lg: 20, xl: 20 }}>
                                            <Grid item xs={2} sm={2} md={5} lg={6} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>

                                            <Grid item xs={6} sm={6} md={4} lg={3} xl={2} onClick={() => {handleClick(index,question.questionId,1)}}>
                                                <item>
                                                    <div className="yes-no">
                                                        <div className="yes-no-text-qp">
                                                            Sí
                                                        </div>
                                                    </div>
                                                </item>
                                            </Grid>

                                            <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                                                <item>
                                                </item>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={4} lg={3} xl={2} onClick={() => {handleClick(index,question.questionId,2)}}>
                                                <item>
                                                    <div className="yes-no">
                                                        <div className="yes-no-text-qp">
                                                            No
                                                        </div>
                                                    </div>
                                                </item>
                                            </Grid>

                                            <Grid item xs={2} sm={2} md={5} lg={6} xl={7}>
                                                <item>
                                                </item>
                                            </Grid>
                                        </Grid>
                                        <div className="yes-no-footer">
                                            <div className="yes-no-footer-text">
                                                SELECCIONA UNA OPCIÓN
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        );

                    })}

                </div> }
            { pollEnd &&
                <div>
                    <div className="end-poll">
                        {skinType === 1 &&
                            <img
                            className="end-poll-img"
                            src={thanksSC}
                            alt=""
                            />
                        }

                        {skinType === 2 
                        
                        }

                        {skinType === 3 &&
                            <div className="end-poll-text-dv">
                                ¡GRACIAS POR PARTICIPAR!
                            </div>
                        }
                    </div>
                    <div className="end-poll">
                        {skinType === 1 
                           
                        }

                        {skinType === 2 &&
                            <img
                            className="end-poll-img"
                            src={thanksQP}
                            alt=""
                            />
                        }

                        {skinType === 3 &&
                            <img
                                className="end-poll-img"
                                src={thanksDV}
                                alt=""
                            />
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default App;
